import React from "react";
import { Row, Col, Button, Badge } from "reactstrap";

export const ViewProfile = ({ userProfileData, profileType }) => {
  if (profileType === "client") {
    const {
      companyName,
      companyWebsite,
      address,
      state,
      city,
      zipPostalCode,
      country,
      aboutCompany,
      companyActivity,
      yourName,
      email,
      yourRole,
    } = userProfileData;

    return (
      <div>
        <div>Logo</div>
        <h1>{companyName}</h1>
        <div>
          {companyWebsite && (
            <Badge color="primary" href={companyWebsite}>
              website
            </Badge>
          )}
        </div>

        <div>
          <h5 style={{ color: "grey", paddingTop: 10 }}>Activity</h5>
          <h5>{companyActivity}</h5>
        </div>

        <div>
          <h5 style={{ color: "grey", paddingTop: 10 }}>About</h5>
          <h5>{aboutCompany}</h5>
        </div>

        <div>
          <h5 style={{ color: "grey", paddingTop: 10 }}>Contact</h5>
          <h4>Name : {yourName} </h4>
          <h4>Email : {email} </h4>
          <h4>Role : {yourRole} </h4>
        </div>
        <div>
          <h5 style={{ color: "grey", paddingTop: 10 }}>Address</h5>
          <h5>{address}</h5>
          <h5>
            {state}, {city}, {zipPostalCode}, {country}
          </h5>
        </div>
      </div>
    );
  } else if (profileType === "freelancer") {
    const {
      yourName,
      jobCategory,
      address,
      state,
      city,
      zipPostalCode,
      country,
      description,
      education,
      email,
      phoneNumber,
      dob,
      totalYearOfExperience,
      experienceDetail,
      lastRole,
      skills,
      gender,
      languages,
      nationality,
      salary
    } = userProfileData;

    return (
      <div>
        <div>Logo</div>
        <h1>{yourName}</h1>

        <div>
          <h5 style={{ color: "grey", paddingTop: 10 }}>Activity</h5>
          <h5>{jobCategory}</h5>
        </div>
        <div>
          <h5 style={{ color: "grey", paddingTop: 10 }}>Last Role</h5>
          <h5>{lastRole}</h5>
        </div>
        <div>
          <h5 style={{ color: "grey", paddingTop: 10 }}>About</h5>
          <h5>{description}</h5>
        </div>
        <div>
          <h5 style={{ color: "grey", paddingTop: 10 }}>Expected Salary</h5>
          <h5>${salary[1]}- ${salary[0]}</h5>
        </div>

        <div>
          <h5 style={{ color: "grey", paddingTop: 10 }}>Total Experience</h5>
          <h5>{totalYearOfExperience}</h5>
        </div>

        <div>
          <h5 style={{ color: "grey", paddingTop: 10 }}>Experience Details</h5>
          <h5>{experienceDetail}</h5>
        </div>
        
        <div>
          <h5 style={{ color: "grey", paddingTop: 10 }}>Skills</h5>
          <h5>{skills}</h5>
        </div>

        <div>
          <h5 style={{ color: "grey", paddingTop: 10 }}>Education</h5>
          <h5>{education}</h5>
        </div>
        
        <div>
          <h5 style={{ color: "grey", paddingTop: 10 }}>Language</h5>
          <h5>{languages}</h5>
        </div>

        <div>
          <h5 style={{ color: "grey", paddingTop: 10 }}>DOB</h5>
          <h5>{dob}</h5>
        </div>
       
        <div>
          <h5 style={{ color: "grey", paddingTop: 10 }}>Gender</h5>
          <h5>{gender}</h5>
        </div>
        
        <div>
          <h5 style={{ color: "grey", paddingTop: 10 }}>Nationality</h5>
          <h5>{nationality}</h5>
        </div>

        <div>
          <h5 style={{ color: "grey", paddingTop: 10 }}>Contact</h5>
          <h4>Name : {yourName} </h4>
          <h4>Email : {email} </h4>
          <h4>Phone Number : {phoneNumber} </h4>
        </div>
        <div>
          <h5 style={{ color: "grey", paddingTop: 10 }}>Address</h5>
          <h5>{address}</h5>
          <h5>
            {state}, {city}, {zipPostalCode}, {country}
          </h5>
        </div>
      </div>
    );
  }
};
