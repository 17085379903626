import { useEffect, useState } from "react";
import { getAuth, updateProfile } from "firebase/auth";
import { useAuthValue } from "shared/AuthContext";
import { Container, Row, Col, Button, Modal } from "reactstrap";
import { MainInput } from "components/Form";
import { DB_COMPANY_PROFILE, DB_USER_CONFIG, DB_FREELANCER_PROFILE } from "shared/Keys";
import { setDoc, doc, addDoc, collection, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from "firebase-config";
import { useNavigate } from "react-router-dom";
import { getProfileUserData } from "dataserivce/profile/profileApi";
import { ViewProfile } from "./viewProfile";
import { userClientDetails, userFreelancerDetails, ProfileTypeInput } from "./constants";

// Select Ptofile Type
import freelancerIcon from "assets/images/icons/freelancer.svg";
import clientIcon from "assets/images/icons/client.svg";
import checkIcon from "assets/images/icons/check.svg";
import { GrayBackgroundTheme } from "shared/Theme";

export default function Profile() {
  const navigate = useNavigate();

  const [profileDataView, setProfileDataView] = useState(false);
  const [selectProfileTypeView, setSelectProfileTypeView] = useState(false);
  const [formProfileView, setFormProfileView] = useState(false);
  const [loading, setLoading] = useState(true);

  const { currentUser, userConfig, authStateChanged } = useAuthValue();

  const [profileType, setProfileType] = useState(userConfig?.profileType);

  const [profileInputDetails, setProfileInputDetails] = useState(); // userDetails was not Registered

  const [userProfileData, setUserProfileData] = useState(); // userDetails was Registered

  const [editable, setEditable] = useState(false);

  const addressLabel = profileType === "client" ? "Company Address" : "Your Address";
  const addressPlaceHolder =
    profileType === "client" ? "Enter your company address" : "Enter current address";

  // Handlers
  const handleInput = (name, value) => {
    setProfileInputDetails((previous) => ({ ...previous, [name]: value }));
  };

  // Select Ptofile Type
  const typeData = [
    { icon: freelancerIcon, name: "I’m freelancer, looking for work", type: "freelancer" },
    { icon: clientIcon, name: "I’m client, hiring for a project", type: "client" },
  ];

  const [selectProfileType, setSelectProfileType] = useState(userConfig?.profileType || "");

  const CreateUserConfig = (p) => {
    setDoc(doc(db, DB_USER_CONFIG, currentUser?.uid), {
      userId: currentUser?.uid,
      profileType: selectProfileType,
      timestamp: serverTimestamp(),
    })
      .then((e) => {
        authStateChanged();
        setFormProfileView(true);
        setSelectProfileTypeView(false);
        setProfileDataView(false);
      })
      .catch((error) => {
        alert(`Unsuccessful returned error: ${error.message}`);
      });
  };

  useEffect(() => {
    console.log("userConfig useEffect /profile", userConfig);

    if (userConfig) {
      setProfileType(userConfig?.profileType);
      const profile = getProfileUserData(userConfig);
      profile.then((data) => {
        if (data) {
          setUserProfileData(data);
          setProfileDataView(true);
          setSelectProfileTypeView(false);
          setFormProfileView(false);
          setLoading(false);
          setProfileInputDetails(data);
        } else {
          const initialState =
            userConfig?.profileType === "client" ? userClientDetails : userFreelancerDetails;
          setProfileInputDetails(initialState);
          setFormProfileView(true);
          setProfileDataView(false);
          setSelectProfileTypeView(false);
          setLoading(false);
        }
      });
    } else {
      setSelectProfileTypeView(true);
      setFormProfileView(false);
      setProfileDataView(false);
      setLoading(false);
    }
    return () => {};
  }, [userConfig]);

  const saveInDataBaseHandler = () => {
    if (profileType === "client" && !editable) {
      addDoc(collection(db, DB_COMPANY_PROFILE), {
        ...profileInputDetails,
      })
        .then((e) => {
          // Updating Company id in profile
          const companyId = e.id;
          const profileRef = doc(db, DB_USER_CONFIG, userConfig.userId);
          updateDoc(profileRef, {
            companyId: companyId,
          });

          setSelectProfileTypeView(false);
          setFormProfileView(false);
          setProfileDataView(true);
          setUserProfileData(profileInputDetails);
          authStateChanged();

          // navigate("/");
        })
        .catch((error) => {
          console.log(`Unsuccessful returned error ${error}`);
          // alert(`Unsuccessful returned error: ${error.message}`);
        });
    } else if (profileType === "freelancer" && !editable) {
      addDoc(collection(db, DB_FREELANCER_PROFILE), {
        ...profileInputDetails,
      })
        .then((e) => {
          // Updating Company id in profile
          const freelancerId = e.id;
          const profileRef = doc(db, DB_USER_CONFIG, userConfig.userId);
          updateDoc(profileRef, {
            freelancerId: freelancerId,
          });
          setSelectProfileTypeView(false);
          setFormProfileView(false);
          setProfileDataView(true);
          setUserProfileData(profileInputDetails);
          authStateChanged();

          // navigate("/");
        })
        .catch((error) => {
          console.log(`Unsuccessful returned error ${error}`);
          // alert(`Unsuccessful returned error: ${error.message}`);
        });
    } else if (profileType === "freelancer" && editable) {
      const freelancerProfile = doc(db, DB_FREELANCER_PROFILE, userConfig?.freelancerId);
      setDoc(freelancerProfile, { ...profileInputDetails }, { merge: true });
      setSelectProfileTypeView(false);
      setFormProfileView(false);
      setProfileDataView(true);
      setUserProfileData(profileInputDetails);
    } else if (profileType === "client" && editable) {
      const freelancerProfile = doc(db, DB_COMPANY_PROFILE, userConfig?.companyId);
      setDoc(freelancerProfile, { ...profileInputDetails }, { merge: true });
      setSelectProfileTypeView(false);
      setFormProfileView(false);
      setProfileDataView(true);
      setUserProfileData(profileInputDetails);
    }
  };

  const InputForm = (inputField) => {
    return (
      inputField &&
      inputField?.map((item, index) => {
        const { sm = 6 } = item;
        return (
          <Col sm={sm}>
            <div key={index}>
              <MainInput
                label={item.label}
                type={item.type}
                name={item.name}
                value={item.value}
                placeholder={item.placeholder}
                onChange={handleInput}
                options={item.selectOptions}
                maxLength={item.maxLength}
              />
            </div>
          </Col>
        );
      })
    );
  };

  const Address = (labelAddress, placeholder) => {
    return (
      <div>
        <MainInput
          label={labelAddress}
          name="address"
          value={profileInputDetails?.address}
          placeholder={placeholder}
          onChange={handleInput}
        />
        <Row>
          <Col md={3} className="mb-4">
            <div className="job-type-wrapper mb-1">
              <div className="job-type-action">
                <MainInput
                  label="Country"
                  type="select"
                  name="country"
                  value={profileInputDetails?.country}
                  placeholder="Select Country"
                  onChange={handleInput}
                  options={[
                    { name: "India", value: "India" },
                    { name: "Japan", value: "Japan" },
                    { name: "Netherlands", value: "Netherlands" },
                    { name: "Ireland", value: "Ireland" },
                    { name: "Finland", value: "Finland" },
                  ]}
                />
              </div>
            </div>
          </Col>
          <Col md={3} className="mb-4">
            <MainInput
              label="State"
              name="state"
              value={profileInputDetails?.state}
              placeholder="Select State"
              onChange={handleInput}
            />
          </Col>
          <Col md={3} className="mb-4">
            <MainInput
              label="City"
              name="city"
              value={profileInputDetails?.city}
              placeholder="Select City"
              onChange={handleInput}
            />
          </Col>
          <Col md={3} className="mb-4">
            <MainInput
              label="Zip/Postal Code"
              name="zipPostalCode"
              value={profileInputDetails?.zipPostalCode}
              placeholder="Enter "
              onChange={handleInput}
            />
          </Col>
        </Row>
      </div>
    );
  };

  const InputFieldProfile = () => {
    return <Row>{InputForm(ProfileTypeInput(profileType, profileInputDetails))}</Row>;
  };

  const CreateProfile = () => {
    return (
      <div style={{ marginTop: 10 }}>
        {/* <div className="shadow w-60 h-50 d-inline-block p-3 mb-10 bg-body rounded"> */}
        <div>
          <div>
            {InputFieldProfile()}
            {Address(addressLabel, addressPlaceHolder)}
          </div>
          <div className="px-5 mt-5 mx-auto" style={{ maxWidth: 373 }}>
            <Button className="w-100" color="primary" onClick={saveInDataBaseHandler}>
              Submit
            </Button>

            {editable && (
              <Button
                style={{ marginRight: 10, borderRadius: 10 }}
                color="primary"
                onClick={() => {
                  setProfileDataView(true);
                  setFormProfileView(false);
                  setEditable(false);
                }}
              >
                Cancel
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  };

  const SelectProfileTypeViewFn = () => {
    return (
      <section className="py-5 profile-type">
        <GrayBackgroundTheme />
        <Container>
          <div className="mx-auto" style={{ maxWidth: 780 }}>
            <h1 className="text-center mb-5">Find a job or post job</h1>
            {/* <pre>{JSON.stringify({ userConfig, profileType }, null, 2)}</pre> */}
            <Row>
              {typeData.map(({ icon, name, type }, index) => (
                <Col md={6} key={index} className="mb-4">
                  <div className="profile-type-card" onClick={() => setSelectProfileType(type)}>
                    <div className="mb-4">
                      <img src={icon} alt={name} />
                    </div>
                    {(type === selectProfileType ||
                      (!selectProfileType && type === userConfig?.profileType)) && (
                      <img className="active-icon" src={checkIcon} alt="active" />
                    )}
                    <p className="name">{name}</p>
                  </div>
                </Col>
              ))}
            </Row>
            <div className="px-5 mt-5 mx-auto" style={{ maxWidth: 373 }}>
              <Button color="primary" className="w-100" onClick={CreateUserConfig}>
                Submit
              </Button>
            </div>
          </div>
        </Container>
      </section>
    );
  };

  return (
    <section className="py-5">
      <Container>
        <div className="form-screen">
          <h1 className="title">Manage Profile 💼</h1>
          {/* Select  profile Type  */}
          {selectProfileTypeView && SelectProfileTypeViewFn()}

          {/*Create a Profile */}
          {formProfileView && CreateProfile()}

          {/* View Profile */}
          {profileDataView && userProfileData && (
            <div>
              <div className="container p-5 my-5 border">
                <div>
                  <ViewProfile profileType={profileType} userProfileData={userProfileData} />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flex: 1,
                }}
              >
                <Button
                  color="primary"
                  className="submit w-100"
                  onClick={() => {
                    console.log("I am Click ");
                    setProfileDataView(false);
                    setFormProfileView(true);
                    setEditable(true);
                  }}
                >
                  Edit
                </Button>
              </div>
            </div>
          )}
        </div>
      </Container>
    </section>
  );
}
