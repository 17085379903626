import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
// import {applyJobApi} from 'dataserivce/appliedJob/appliedjob-api'

export const ProfileModal = ({ isOpen, toggle, args, userProfileData, onClick }) => {

    // console.log('userProfileData',userProfileData)
  

  return (
    <Modal isOpen={isOpen} toggle={toggle} {...args}>
        <ModalBody>

      <div className="center container p-5 my-5 border">
        <div>
            <Col md={3}>
              <div>
                <h1>
                  <strong>{ userProfileData?.yourName}</strong>
                </h1>
              </div>
              <div>
                <h3>{userProfileData?.yourRole}</h3>
              </div>
              <div>
                <h3>{userProfileData?.jobCategory}</h3>
              </div>
            </Col>
            <Col md={0}>
              <Button color="primary" onClick={()=>onClick(userProfileData.profileId,userProfileData.jobId,'selected')}>
                Apply
              </Button>
              <Button color="secondary" onClick={()=>onClick(userProfileData.profileId,userProfileData.jobId,'reject')}>
                Rejected
              </Button>
              <Button style={{ marginLeft:'1em',borderWidth:'0.05em',borderColor:'black'}} color="white" onClick={toggle}>
                Cancel
              </Button>
            </Col>
        </div>

        <div style={{ paddingTop:'2em'}}>
          <h3>About</h3>
        </div>
        <div>{userProfileData?.description}</div>

        <div style={{ paddingTop:'2em'}}>
          <h3>Additional Details</h3>
        </div>

        <div style={{ paddingTop:'1em'}}>
          <h3>Total Experience</h3>
          <p>{userProfileData?.totalYearOfExperience}</p>
        </div>
        <div>{userProfileData?.experienceDetail}</div>

        <div style={{ paddingTop:'1em'}}>
          <h3>Skills</h3>
        </div>
        <div>{userProfileData?.skills}</div>
        
        <div style={{ paddingTop:'1em'}}>
          <h3>Location</h3>
        </div>

        <p>{userProfileData?.country} </p> <p>{userProfileData?.state}</p> <p>{userProfileData?.city}</p>
        
        <div style={{ paddingTop:'1em'}}>
          <h3>Contact</h3>
        </div>

        <p>Email {userProfileData?.email}</p>
        <p>Phone {userProfileData?.phoneNumber}</p>

      </div>
      </ModalBody>

    </Modal>
  );
};
