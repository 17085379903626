import { MainInput } from "components/Form";
import { AddEducationHistoryModal, AddExperienceModal } from "components/modal";
import cx from "cx";
import { useDbAddNewEntry } from "hooks";
import { useState } from "react";
import { Button, Container, Row, Col } from "reactstrap";
import { DB_FIND_JOB } from "shared/Keys";

export default function FindJob() {
  const { onDbSubmit } = useDbAddNewEntry();
  const [experienceModal, setExperienceModal] = useState(false);
  const [educationHistory, setEducationHistory] = useState(false);

  const [inputs, setInputs] = useState({
    title: "",
    currentCompany: "",
    description: "",
    jobType: "",
  });

  const { title, description, jobType, currentCompany } = inputs;

  const handleInput = (name, value) => {
    setInputs((previous) => ({ ...previous, [name]: value }));
  };

  const submitValue = {
    ...inputs,
  };

  const onSubmit = () => {
    onDbSubmit({ dbKey: DB_FIND_JOB, variables: submitValue });
  };

  const jobTypeList = [
    // { name: "Full-Time" },
    { name: "Part-Time" },
    // { name: "Contract" },
    { name: "Freelance" },
    { name: "Temporary" },
    { name: "Permanent" },
    
  ];

  function renderJobType() {
    return (
      <div className="main-input">
        <label className="form-label">Job Type</label>
        <div className="job-type-wrapper mb-4">
          {jobTypeList.map((data) => (
            <div className="job-type-action">
              <Button
                className={cx({ active: jobType === data.name })}
                onClick={() => handleInput("jobType", data.name)}
              >
                {data?.name}
              </Button>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <>
      <AddExperienceModal active={experienceModal} close={() => setExperienceModal(false)} />
      <AddEducationHistoryModal
        active={educationHistory}
        close={() => setEducationHistory(false)}
      />
      <section className="py-5">
        <Container>
          <div className="post-job form-screen">
            <h1 className="title">
              Find a job from anywhere,
              <br />
              anytime!💼
            </h1>
            <Row className="mb-4">
              <Col sm={6}>
                <MainInput
                  label="Profile Title"
                  name="title"
                  value={title}
                  placeholder="Enter job title"
                  onChange={handleInput}
                />
              </Col>
              <Col sm={6}>
                <MainInput
                  label="Current Company"
                  name="currentCompany"
                  value={currentCompany}
                  placeholder="Enter current company name"
                  onChange={handleInput}
                />
              </Col>
              <Col sm={6}>
                <div className="main-input">
                  <label className="form-label">Job Type</label>
                  <div className="d-flex">
                    <Button color="selection" className={cx("me-3", { active: false })}>
                      Fresher
                    </Button>
                    <Button
                      color="selection"
                      className={cx({ active: true })}
                      onClick={() => setExperienceModal(true)}
                    >
                      Add Experience
                    </Button>
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div className="main-input">
                  <label className="form-label">Job Type</label>
                  <div className="d-flex">
                    <Button
                      color="selection"
                      className={cx({ active: true })}
                      onClick={() => setEducationHistory(true)}
                    >
                      Add Education
                    </Button>
                    <Button color="selection" className={cx("ms-3", { active: false })}>
                      Nothing to add
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <MainInput
              type="textarea"
              label="Description"
              name="description"
              value={description}
              placeholder="Enter post description"
              onChange={handleInput}
            />

            {/* <pre>{JSON.stringify({ inputs }, null, 2)}</pre> */}
            <div className="px-5 mt-5 mx-auto" style={{ maxWidth: 373 }}>
              <Button color="primary" className="w-100" onClick={onSubmit}>
                Submin
              </Button>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
