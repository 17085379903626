import GetInTouch from "components/GetInTouch";
import Banner from "./Banner";
import Crew from "./Crew";
import Info from "./Info";

export default function AboutUs() {
  return (
    <>
      <Banner />
      <Info />
      <Crew />
      <GetInTouch />
    </>
  );
}
