import { CountrySelection, MainInput } from "components/Form";
import PrimaryLocation from "components/Form/PrimaryLocation";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

export default function AddExperienceModal(props) {
  const { active = true, close = () => {} } = props;
  return (
    <Modal isOpen={active} toggle={close} centered size="lg">
      <ModalHeader toggle={close}>Add work experience</ModalHeader>
      <ModalBody>
        <Row className="gx-3">
          <Col xs="6">
            <MainInput label="Profile Title" />
          </Col>
          <Col xs="6">
            <MainInput label="Company" placeholder="Ex: Google" />
          </Col>
          <Col xs={6}>
            <PrimaryLocation label="Location" placeholder="Ex: San Francisco" />
          </Col>
          <Col xs={6}>
            <CountrySelection label="Country" />
          </Col>
          <Col xs={6}>
            <Row>
              <Col xs="6">
                <MainInput
                  label="Start Date"
                  type="select"
                  placeholder="Month"
                  options={[
                    { name: "option 1", value: "option-1" },
                    { name: "option 1", value: "option-1" },
                  ]}
                />
              </Col>
              <Col xs="6">
                <MainInput
                  label={<>&nbsp;</>}
                  type="select"
                  placeholder="Year"
                  options={[
                    { name: "option 1", value: "option-1" },
                    { name: "option 1", value: "option-1" },
                  ]}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={6}>
            <Row>
              <Col xs="6">
                <MainInput
                  label="End Date"
                  type="select"
                  placeholder="Month"
                  options={[
                    { name: "option 1", value: "option-1" },
                    { name: "option 1", value: "option-1" },
                  ]}
                />
              </Col>
              <Col xs="6">
                <MainInput
                  label={<>&nbsp;</>}
                  type="select"
                  placeholder="Year"
                  options={[
                    { name: "option 1", value: "option-1" },
                    { name: "option 1", value: "option-1" },
                  ]}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <center>
          <Button color="primary" className="submit mt-4 px-5 rounded-5">
            Save
          </Button>
        </center>
      </ModalBody>
    </Modal>
  );
}
