import { db } from "firebase-config";
import { DB_JOB_POST, DB_FREELANCER_PROFILE } from "shared/Keys";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  orderBy,
  updateDoc
} from "firebase/firestore";

export const viewApplicant = () => {
  

  const fetchJobPosted =  async(companyId) => {

    const jobpost = collection(db, DB_JOB_POST);
    const checkingCompany = query(jobpost, where("companyId", "==", companyId));

    const querySnapshot = await getDocs(checkingCompany);
    const listData= []

    querySnapshot.forEach((doc) => {

      listData.push({...doc.data(),jobId:doc.id})   
    });
    
    return listData
  }

  const fetchUsersDetails = async (profileIdList) => {
    if(profileIdList !== undefined ){
      const listProfile = []

      for (const id of  profileIdList){
        
        const docRef = doc(db, DB_FREELANCER_PROFILE, id);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          data.profileId = id;
          // console.log('fetchUsersDetails',data)
          listProfile.push(data)
        }
      }
      return listProfile
    }
  
  };

  const changeStatus = async(profileId,appliedJobId,status) => {

    const freelancerPortfolio = doc(db, DB_FREELANCER_PROFILE,profileId );
    const jobDetails = doc(db, DB_JOB_POST,appliedJobId );

    
    
    const updateStatus= async(db)=>{
      const docSnap = await getDoc(db);
      if (docSnap.exists()) {
        const appliedUsers=[]
        const appliedJobUserList= docSnap.data().appliedUsers
        appliedJobUserList.forEach((item)=>{
          console.log('appliedJobUserList',item)
          if (item?.jobPostId===appliedJobId){
            appliedUsers.push({
              jobPostId:item.jobPostId,
              status:status
            })
          }
          else if(item?.profileId===profileId){
            appliedUsers.push({
              profileId:item.profileId,
              status:status
            })
          }
          else{
            appliedUsers.push(item)
          }
        })
        await updateDoc(db, {
          appliedUsers:appliedUsers
        });
  
      }
    }
    updateStatus(freelancerPortfolio);
    updateStatus(jobDetails);

   

    
  
 



  }





  return {
    fetchJobPosted,
    fetchUsersDetails,
    changeStatus
  }




  
}
