import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import Router from "router";
import { AuthProvider } from "shared/AuthContext";

export default function App() {


  return (
      <AuthProvider>
        <Router />
      </AuthProvider>
  );
}
