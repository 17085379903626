import React, { useState, useEffect } from "react";

import { useAuthValue } from "shared/AuthContext";
import { Row, Col } from "reactstrap";
import {ProfileModal} from '../../components/profile-job-modal2/profile-modal'
import { viewApplicant } from '../../dataserivce/viewApplicant/view-applicant'

export default function Applied() {
  const { currentUser, userConfig } = useAuthValue();
  const [userAppliedDetailed, setUserAppliedDetailed] = useState();
  const companyId = userConfig?.companyId;
  const { fetchJobPosted,fetchUsersDetails,changeStatus }=viewApplicant();
  const [jobDetails,setJobDetails]=useState([]);
  const [modalVisible,setModalVisible]=useState(false);
  const [userProfileData,setUserProfileData]=useState();
  
  const toggle = () => setModalVisible(!modalVisible);
  const args ={ fullscreen : true, scrollable:false }


  const selectUserHandler=(profileId,appliedJobId,status)=>{

    // console.log('*** selectUserHandler ***',profileId,appliedJobId,status)
    changeStatus(profileId,appliedJobId,status)
    
    // if (profileType==='freelancer'){
      // console.log('I am a freelancer......',id)
    //   applyJobApi(jobId,freelancerId,profileType,jobCompanyId) // jobId = jobId, jobCompanyId = companyId
      toggle()

    // }
  }

  

  const fetchAppliedUserProflie = async(jobDetailsList) => {

    
    const applicants =jobDetailsList?.map((item) => {
      // console.log('fetchAppliedUserProflie data',item.appliedUsers)
        const profile = {
          jobTitle:item.title,
          jobId:item.jobId,
          appliedUsers:item?.appliedUsers?.map((item)=>item.profileId)
        }
        return profile
    })
    
    applicants?.map((item, index) => {
      // console.log('item.appliedUsers',item.appliedUsers)

      fetchUsersDetails(item?.appliedUsers)
      .then((proflieData)=>{
        setJobDetails((st)=>[ ...st,
          {
           jobId:item.jobId,
           jobTitle:item.jobTitle,
           userProfile:proflieData
          }
        ])
      })
    });
    
  }


  const ViewApplication = (userProfile,jobId) => {
    
    return userProfile?.map((item,index)=>{
      
      // console.log("** item **", item);
      return (
          <Col key = {index} sm={4}>
            <div onClick={()=>{setUserProfileData({...item,jobId:jobId});setModalVisible(true)}} className="container p-5 my-5 border">
              <h5>{item.yourName}</h5>
            </div>
          </Col>
      );
    })
    
    
  };


  const ViewUserApplied = () => {
    return jobDetails?.map((item, index) => {
      // console.log('jobDetails item',item)
      return (
        <div key={index} className="container p-5 my-5 border">
          <div>
            <h2>
              <strong>{item?.jobTitle}</strong>
            </h2>
            <Row>
              <Col sm={2}>
                {/* <h5>{item.jobCategory}</h5> */}
              </Col>
              <Col sm={0}>
                {/* <h5>{item.jobType}</h5> */}
              </Col>
            </Row>
            <Row>
            {ViewApplication(item.userProfile,item.jobId)}
            </Row>
          </div>
        </div>
      );
    });
  };





  useEffect(() => {
    
    
    if (userConfig) {
      // console.log("** count **");

      fetchJobPosted(companyId)
    .then((jobDetailsData)=>{
      fetchAppliedUserProflie(jobDetailsData)
    })
    }

    return () => {};
  }, [userConfig]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
          alignContent: "center",
        }}
      >
        {/* View Profile */}
      </div>
      <div>
        {ViewUserApplied()}
        <ProfileModal isOpen={modalVisible} toggle={toggle} args={args}  userProfileData={userProfileData} onClick= {(profileId,appliedJobId,status)=>selectUserHandler(profileId,appliedJobId,status)} />

        {/* <pre>{JSON.stringify({ userProfileData }, null, 2)}</pre> */}
      </div>
      
    </div>
  );
}
