import Layout from "components/Layout";
import {
  Home,
  SignUp,
  PostJob,
  VerifyEmail,
  Login,
  ProfileType,
  FindJob,
  Profile,
  Applied,
  Team,
  AboutUs,
} from "pages";
import { useAuthValue } from "shared/AuthContext";
// import { signOut } from "firebase/auth";
// import { auth } from "firebase-config";
const { Outlet, Navigate, Route, Routes, BrowserRouter } = require("react-router-dom");

export default function Router() {
  const { currentUser, userConfig } = useAuthValue();

  const PrivateRoutes = () => {
    console.log("I am in private route currentUser", currentUser);

    // console.log('userConfig',userConfig)
    // return ( <Outlet/> )
    return currentUser ? <Outlet /> : <Navigate to="/login" />;
  };
  const LoggedUser = () => {
    console.log("I am sign up users", currentUser);

    return currentUser ? <Navigate to="/" /> : <Outlet />;
  };

  return (
    <>
      {/* <pre>{JSON.stringify({ currentUser }, null, 2)}</pre>
      <span onClick={() => signOut(auth)}>Sign Out</span> */}
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            {/* <Route path="/team" element={<Team />} /> */}
            <Route path="/about-us" element={<AboutUs />} />
          </Route>
          <Route>
            <Route path="/sign-up" element={currentUser ? <Navigate to="/" /> : <SignUp />} />
            <Route path="/login" element={currentUser ? <Navigate to="/" /> : <Login />} />
          </Route>

          <Route element={<PrivateRoutes />}>
            <Route element={<Layout />}>
              <Route path="/profile-type" element={<ProfileType />} />
              <Route path="/post-job" element={<PostJob />} />
              <Route path="/find-job" element={<FindJob />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/applied" element={<Applied />} />
              <Route path="/verify-email" element={<VerifyEmail />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}
